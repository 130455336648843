import { graphql } from "gatsby";
import React from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Section from "../components/ui/Section/Section";

const Page = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Section
      title={data.datoCmsPolicyPage.title}
      style={{ margin: "2rem auto" }}
    >
      <Helmet>
        <title>{t("Команда PPnP")}</title>
        <meta name="yandex-verification" content="ca9a11062897f86a" />
      </Helmet>
      <div
        style={{ display: "grid", gridGap: "1rem" }}
        dangerouslySetInnerHTML={{ __html: data.datoCmsPolicyPage.text }}
      />
    </Section>
  );
};

export const query = graphql`
  query($language: String!) {
    datoCmsPolicyPage(locale: { eq: $language }) {
      title
      text
    }
  }
`;

export default Page;
